/* requires:
core-utils.js
*/
var MDM = MDM || {};
MDM.SharedNav = MDM.SharedNav || {};

MDM.SharedNav.Logger = class {
    constructor() {

        //Push messages to servers if max message queue is reached or every x second, whichever comes first.
        this.MaxMessageQueue = 10;
        this.SendEventTimer = 2500; //ms
        this.queue = [];
        this.clientLoggingEnabled = false;

        setInterval(() => this.sendEvents(), this.SendEventsTimer);
    }

    getUserId() {
        let uuidValue = MDM.SharedNav.Utils.getCookieValue("menuuuid");

        if (!uuidValue) {
            uuidValue = crypto.randomUUID();
            MDM.SharedNav.Utils.setCookie("menuuuid", uuidValue);
        }

        return uuidValue;
    }

    queueMessage(message) {
        if (typeof LogRocket !== 'undefined') LogRocket.info(`${this.getUserId()} ${message}`);
        this.queue.push(message);

        if (this.queue.length >= this.MaxMessageQueue) {
            this.sendEvents();
        }
    }

    sendEvents() {
        if (this.queue.length > 0 && this.clientLoggingEnabled) {
            MDM.SharedNav.Utils.makeRequest(
                {
                    method: "POST",
                    url: `${MDM.SharedNav.Params.navUrl}/sharedNav/Log`,
                    params: {
                        "userId": this.getUserId(),
                        "messages": this.queue
                    },
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    }
                }, false);
            this.queue = [];
        }
    }

    menuCheck() {
        let menu = document.getElementById(MDM.SharedNav.Core.ElementIds.navigationContainer);
        if (MDM.SharedNav.Utils.nullOrUndefined(menu)) {
            MDM.SharedNav.logger.queueMessage("Menu check failed");
        }
        else {
            clearInterval(MDM.SharedNav.menuCheckInterval);
        }
    }
}

MDM.SharedNav.logger = new MDM.SharedNav.Logger();

//Error listener
window.addEventListener("error", (event) => {
    let message = `${event.message} ${event.error.stack}`;
    MDM.SharedNav.logger.queueMessage(message);
})

MDM.SharedNav.menuCheckInterval = setInterval(() => { MDM.SharedNav.logger.menuCheck() }, 5000);